<template>
  <div class="getPoints">
    <template v-if="isSuccess === true">
      <img src="./img/success.png" alt="" />
      <div class="tip">
        <div class="icon">
          <img src="./img/success_icon.png" alt="" />
        </div>
        <div class="text success">积分获取成功！</div>
      </div>
    </template>
    <template v-if="isSuccess === false">
      <img src="./img/error.png" alt="" />
      <div class="tip">
        <div class="icon">
          <img src="./img/error_icon.png" alt="" />
        </div>
        <div class="text error">积分获取失败！</div>
      </div>
    </template>
    <template v-if="isSuccess === true">
      <div class="point">
        <span class="symbol">{{
          pointsData.operationType == 1 ? "+" : "-"
        }}</span>
        <span class="count">{{ pointsData.tokenPoint }}</span>
        <span class="unit">分</span>
      </div>
    </template>
    <div class="btnSubmit">
      <div class="submit" @click="toMyPoint">我的积分</div>
    </div>
  </div>
</template>

<script>
import { pointsUrl, pointsList, getPoints } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "points",
  data() {
    return {
      isSuccess: undefined,
      pointsData: {
        operationType: undefined,
        tokenPoint: 0,
      },
    };
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async created() {
    await this.addPoints();
  },
  methods: {
    toMyPoint() {
      this.$router.push({
        name: "mall",
        query: {
          fromGetpoint: 1,
        },
      });
    },
    async addPoints() {
      let operationType = getHashParam("operationType");
      let settingCode = getHashParam("settingCode");
      let recordType = getHashParam("recordType");
      let params = {
        communityId: this.communityId,
        roomId: this.roomId,
        userId: this.userId,
        tenantId: this.tenantId,
        operationType,
        settingCode,
        recordType,
      };
      const res = await this.$axios.post(`${getPoints}`, params, {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      });
      if (res.code === 200) {
        this.$toast("获取积分成功");
        this.isSuccess = true;
        this.pointsData = res.data;
      } else {
        let msg = res.msg || "获取积分失败";
        this.isSuccess = false;
        this.$toast(msg);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.getPoints {
  box-sizing: border-box;
  min-height: 100%;
  text-align: center;
  > img {
    margin-top: 180px;
    width: 328px;
    height: 198px;
    vertical-align: middle;
    overflow: hidden;
  }
  .tip {
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 42px;
      height: 42px;
      margin-right: 18px;
      > img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        vertical-align: middle;
      }
    }
    .text {
      font-size: 42px;
      font-weight: bold;
      &.success {
        color: #3781ff;
      }
      &.error {
        color: #e02020ff;
      }
    }
  }

  .point {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 44px;
    margin-top: 32px;
    color: #347eff;
    font-size: 32px;
    font-weight: bold;
    .symbol {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-family: "Courier New", Courier, monospace;
    }
    .count {
      margin-right: 8px;
    }
    .unit {
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
    }
  }

  .btnSubmit {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    .submit {
      width: 150px;
      height: 66px;
      font-size: 28px;
      line-height: 66px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
    }
  }
  // min-height: 100vh;
  // background: #f3f4f9;
  // position: relative;
  // overflow: hidden;
  // box-sizing: border-box;
  // .title-l,
  // .title-r {
  //   font-size: 48px;
  //   text-align: center;
  //   margin: 100px 0 0 0;
  // }
  // .img {
  //   width: 180px;
  //   height: 180px;
  //   margin: 40px auto;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  // .pointBody {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   .pointBody-l {
  //     font-size: 40px;
  //     margin-right: 10px;
  //   }
  //   .pointBody-r {
  //     font-size: 60px;
  //   }
  // }
  // .myPoint {
  //   border: 1px solid #f1a674;
  //   width: 320px;
  //   height: 90px;
  //   font-size: 40px;
  //   line-height: 90px;
  //   margin: 200px auto;
  //   text-align: center;
  //   color: #f1a674;
  //   border-radius: 20px;
  // }
}
</style>
